import React from 'react';
import { useTranslation } from 'react-i18next';
import '@i18n/i18n';
import classNames from 'classnames';

import { usePlan } from '@util/providers/Plan';

export default function Toggler({ justify = 'left', children }) {
  const { t } = useTranslation();
  const { termIsYearly, setTermIsYearly } = usePlan();

  return (
    <div
      className={classNames('d-flex', {
        'justify-content-center': justify === 'center',
      })}
    >
      <div className="btn-group mt-4 mb-2 my-sm-4" role="group">
        <button
          className={classNames('btn', {
            'btn-primary': termIsYearly === false,
            'btn-outline-gray  btn-control': termIsYearly === true,
          })}
          type="button"
          id="ButtonMonthly"
          data-testid="ButtonMonthly"
          onClick={() => setTermIsYearly(false)}
        >
          {t('monthly')}
        </button>
        <button
          className={classNames('btn', {
            'btn-primary': termIsYearly === true,
            'btn-outline-gray  btn-control': termIsYearly === false,
          })}
          type="button"
          id="ButtonYearly"
          data-testid="ButtonYearly"
          onClick={() => setTermIsYearly(true)}
        >
          {t('yearly')}
        </button>
      </div>
      {children && <div className="align-self-center">{children}</div>}
    </div>
  );
}
