import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import useAxios from 'axios-hooks';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import URLS from '@config/urls';

import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import Toggler from '@components/Miscellaneous/Toggler';
import { PlanCard } from '@ui/PlanCard';
import RateInfo from '@components/PlanCard/RateInfo';
import Checkmark from '@images/checkmarkInverted.svg';
import { useYearlySavings } from '@util/Plans/PlanFunctions';

import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';
import { useInvoice } from '@util/providers/Invoice';
import useSubscription from '@util/hooks/useSubscription';
import { useInvoiceData, useAmendmentParams } from '@util/payment';
import ArrowLink from '@components/Miscellaneous/ArrowLink';
import moment from 'moment';

function isCurrentPlan(plan, ratePlan, currentPlan, currentRatePlan) {
  if (plan?.key === currentPlan?.key) {
    if (plan?.key === 'community') {
      return true;
    }
    if (ratePlan?.key === currentRatePlan?.key) return true;
  }

  return false;
}

// TODO: update with plan card component along with default landing page plan card instances
export default function MyPlanModifyPage() {
  return (
    <ProtectedPage>
      <Rules>
        <PageContent />
      </Rules>
    </ProtectedPage>
  );
}

function PageContent() {
  const { t } = useTranslation();
  const [changes, setChanges] = useState();
  const pageName = t('page:modifyPlan');
  const {
    plans,
    termIsYearly,
    selectedPlan,
    selectedRate,
    setSelectedPlan,
    setSelectedRate,
  } = usePlan();
  const { ssoUser } = useUser();
  const { details, isActiveSubscriber } = useSubscription();
  const { plan: currentPlan, ratePlan: currentRatePlan } = details;
  const { setInvoicePreview } = useInvoice();
  const amendmentParams = useAmendmentParams();
  const { formatInvoice } = useInvoiceData();
  const [
    {
      data: amendmentResponse,
      loading: submittingAmendment,
      //error
    },
    submitAmendment,
  ] = useAxios(
    {
      url: URLS.CREATE_AMENDMENT,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${ssoUser ? ssoUser.access_token : ''}`,
      },
    },
    {
      manual: true,
    }
  );
  const percentSavings = useYearlySavings();

  //* set aligned rate when monthly/yearly toggle is changed
  useEffect(() => {
    selectedPlan && setSelectedRate(getRatePlan(selectedPlan));
    // eslint-disable-next-line
  }, [termIsYearly]);

  //* handle amendment response
  useEffect(() => {
    if (amendmentResponse) {
      setInvoicePreview(
        formatInvoice(amendmentResponse.results[0].invoiceDatas[0], {
          modification: true,
        })
      );
      navigate('/myplan/modify/review');
    }
    // eslint-disable-next-line
  }, [amendmentResponse]);

  function getRatePlan(plan) {
    if (plan.staticRatePlan) return plan.staticRatePlan;
    return plan.ratePlans.find(rp =>
      termIsYearly ? rp.isYearly : rp.isMonthly
    );
  }

  //* check to see if the amendment is a downgrade
  function confirmAmendment({ data }) {
    //* if subscription is expired, push user to payment details to resubscribe
    if (!isActiveSubscriber) {
      return navigate('/paymentdetails');
    }

    if (data.IsFutureChange) {
      //* downgrade
      const changes = [];
      if (
        currentPlan.key === 'professional' &&
        selectedPlan.key === 'personal'
      ) {
        //* plan type downgrade
        changes.push(t('modify.downgradeWarning.planChange'));
      }

      if (currentRatePlan.isYearly && !selectedRate.isYearly) {
        //* plan term downgrade
        changes.push(t('modify.downgradeWarning.termChange'));
      }

      if (!currentRatePlan.active) {
        if (selectedRate.isYearly) {
          changes.push(t('modify.downgradeWarning.termChangeMultiToYearly'));
        } else {
          changes.push(t('modify.downgradeWarning.termChangeMultiToMonthly'));
        }
      }
      setChanges(changes);
    } else {
      //* upgrade
      submitAmendment({ data });
    }
    //    submitAmendment();
  }

  if (!currentPlan && !currentRatePlan) return null;

  const showCommunityCard = currentPlan?.key === 'community';

  return (
    <>
      <SEO title={pageName} />

      <div className="row">
        <div className="col">
          <PageHeader>{pageName}</PageHeader>
        </div>
      </div>

      {!currentRatePlan.active && isActiveSubscriber && (
        <div className="alert alert-info mt-4">
          {t('modify.discontinuedRatePlan')}
        </div>
      )}

      <div className="d-flex justify-content-between">
        <Toggler>
          <h5 className="ml-4 mb-0">
            {/* prettier-ignore */}
            <Trans i18nKey="save17Percent">
              <strong>
                Save
                {{
                  percent: percentSavings,
                }}
                %
              </strong>
              with a yearly plan
            </Trans>
          </h5>
        </Toggler>
      </div>

      <div className="row">
        {plans
          .filter(p => {
            if (showCommunityCard) {
              return p.key !== 'organization';
            } else {
              return p.key !== 'organization' && p.key !== 'community';
            }
          })
          .map((plan, i) => {
            const ratePlan = getRatePlan(plan);
            const isPlanCurrentPlan = isCurrentPlan(
              plan,
              ratePlan,
              currentPlan,
              currentRatePlan
            );

            return (
              <div
                className={
                  showCommunityCard ? 'col-md-4 mb-4' : 'col-md-6 mb-4'
                }
                key={`planCard_${i}`}
              >
                <PlanCard
                  className={classNames('text-left p-0 h-100', {
                    selected: plan.key === selectedPlan.key,
                  })}
                  onClick={() => {
                    // if (!isPlanCurrentPlan) {
                    setSelectedPlan(plan);
                    setSelectedRate(ratePlan);
                    // }
                  }}
                >
                  <PlanCard.Body className="px-4 pt-4 pb-2">
                    <h5 className="pb-3 text-dark">
                      {plan && plan?.name?.toUpperCase()}
                    </h5>
                    <RateInfo rate={ratePlan} plan={plan} myPlan />
                    {plan && plan.key && (
                      <div className="learn-more-link mb-4">
                        <ArrowLink
                          id={'LearnMore' + plan.key}
                          data-testid={'LearnMore' + plan.key}
                          to={`/${plan.key}`}
                        >
                          {t('action.learnMore')}
                        </ArrowLink>
                      </div>
                    )}
                    <Footer
                      plan={plan}
                      ratePlan={ratePlan}
                      isCurrentPlan={isPlanCurrentPlan}
                      isFutureChange={
                        currentRatePlan.active
                          ? ratePlan?.price < currentRatePlan?.price
                          : true
                      }
                    />
                  </PlanCard.Body>
                </PlanCard>
              </div>
            );
          })}
      </div>
      <div className="mt-4">
        <button
          onClick={() => {
            confirmAmendment({ data: amendmentParams });
          }}
          className="btn btn-primary"
          id="ModifyPlanNext"
          disabled={
            currentRatePlan?.id === selectedRate?.id || submittingAmendment
          }
        >
          {submittingAmendment && (
            <>
              <span
                className="spinner-corner spinner-corner-sm align-middle mt-n1"
                role="status"
                aria-hidden="true"
              ></span>{' '}
            </>
          )}
          {t('action.next')}
        </button>
        <button
          type="button"
          onClick={() => navigate('/myplan')}
          id="ModifyPlanBack"
          className="btn btn-outline-secondary"
          disabled={submittingAmendment}
        >
          {t('action.back')}
        </button>
      </div>
      {isActiveSubscriber && <CustomerSupportBlock />}
      <DowngradeWarningModal
        isOpen={changes?.length > 0}
        toggle={() => setChanges()}
        changes={changes}
        confirmationAction={() => submitAmendment({ data: amendmentParams })}
        submitting={submittingAmendment}
      />
    </>
  );
}

function CustomerSupportBlock() {
  const { t } = useTranslation();
  const { bmoUser } = useUser();
  const thirtyDayWindow = moment(
    bmoUser.zuoraInfo.subscriptions[0].subscriptionStartDate
  ).add(30, 'days');
  const remainingThirtyDayWindow = moment(thirtyDayWindow).diff(
    moment(),
    'days'
  );
  const withinThirtyDayWindow =
    remainingThirtyDayWindow >= 0 && remainingThirtyDayWindow <= 29;
  return (
    withinThirtyDayWindow && (
      <div className="row mt-4">
        <div className="col-12">
          <PlanCard className="text-left pl-4 py-2 mb-3 d-inline-block" noHover>
            {/* prettier-ignore */}
            <Trans i18nKey="modify.cancelYourSubscription">
          If you wish to cancel your subscription and receive a full refund, 
          you must contact&nbsp;
            <a
                href={process.env.CONTACT_US}
                rel="noopener noreferrer"
                target="_blank"
              >
              {{ supportLink: t('customerSupport') }}
            </a>
            &nbsp;before&nbsp;
            <b>{{ thirtyDayWindow: thirtyDayWindow.format('LL')}}</b> 
          </Trans>
            .
          </PlanCard>
        </div>
      </div>
    )
  );
}

function Footer({ isCurrentPlan, isFutureChange }) {
  const { t } = useTranslation();
  const checkBoxSize = 16;
  return (
    <div
      className="border-0 pt-0"
      style={{
        lineHeight: 'normal',
      }}
    >
      {isCurrentPlan ? (
        <>
          <img
            src={Checkmark}
            style={{
              maxWidth: checkBoxSize,
              maxHeight: checkBoxSize,
              minHeight: checkBoxSize,
              minWidth: checkBoxSize,
            }}
            alt="current plan"
          />
          <small className="pl-1 text-muted">{t('planCard.currentPlan')}</small>
        </>
      ) : (
        <small className="text-muted">
          {isFutureChange
            ? t('planCard.downgradeNotice')
            : t('planCard.upgradeNotice')}
        </small>
      )}
    </div>
  );
}

function Rules({ children }) {
  const subscription = useSubscription();
  const homeRules = !subscription?.isSubscriber;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}

function DowngradeWarningModal({
  isOpen,
  toggle,
  changes,
  confirmationAction,
  submitting,
}) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} charCode="">
        {t('modify.downgradeWarning.title')}
      </ModalHeader>
      <ModalBody>
        {t('modify.downgradeWarning.prompt')}
        <ul className="my-3">
          {changes?.map((c, i) => <li key={`change_${i}`}>{c}</li>)}
        </ul>
        {t('modify.downgradeWarning.changeNote')}
      </ModalBody>
      <ModalFooter className="pb-5">
        <button
          className="btn btn-primary"
          onClick={confirmationAction}
          id="ModifyPlanProceed"
          disabled={submitting}
        >
          {submitting && (
            <>
              <span
                className="spinner-corner spinner-corner-sm align-middle mt-n1"
                role="status"
                aria-hidden="true"
              ></span>{' '}
            </>
          )}
          {t('action.proceed')}
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={toggle}
          id="ModifyPlanBack"
          disabled={submitting}
        >
          {t('action.back')}
        </button>
      </ModalFooter>
    </Modal>
  );
}
